import { MembershipScopes, User } from '../reducers/auth/userReducer'
import { membershipIsIn } from '../selectors/user.selectors'

const isProduction = process.env.VITE_IS_PROD === 'true'

// Add heap to global window namespace
declare global {
  interface Window {
    heap: {
      identify: (val: string) => void
      addUserProperties: (config: object) => void
    }
  }
}

export const identifyUser = (user: User | null) => {
  if (isProduction && user?.email) {
    const primaryMembership =
      user.memberships?.find((membership) => membership.isPrimary) ?? null
    const isActiveMember = membershipIsIn(
      MembershipScopes.allActiveIncludingUnpaid,
      primaryMembership
    )
    window.heap.identify(user.email)
    window.heap.addUserProperties({
      created_at: user.createdAt,
      admin: user.admin,
      state: isActiveMember ? 'active' : (primaryMembership?.status ?? null),
      deletedAt: user.deletedAt,
      practiceType: user.financialProfile?.practiceType,
      businessEntity: user.financialProfile?.businessEntity,
    })
  }
}
