import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import moment from 'moment'

import PageHeader from '../../../../../components/shared/PageHeader'
import { useReselector } from '../../../../../utils/sharedHooks'
import { selectAnnualTaxFilingFormById } from '../../annualTaxFilingForms.selector'
import { fetchAnnualTaxFilingFormsIfNeeded } from '../../annualTaxFilingForms.slice'
import {
  useFormFlow,
  useSetScreen,
} from '../../../../../components/FormFlow/formFlow'
import {
  Alert,
  GridRowColumn,
  Loader,
} from '../../../../../components/BaseComponents'
import CategorizeTransactionsStep from './CategorizeTransactionsStep'
import { fetchTransactionCategoriesIfNeeded } from '../../../../Reports/reports.slice'
import {
  DELETE_MANUAL_TRANSACTION_KEY,
  fetchFilteredUserTransactions,
  UPDATE_USER_TRANSACTION_KEY,
} from '../../../../Transactions/transactions.slice'
import AddBusinessIncomeStep from './AddBusinessIncomeStep'
import {
  getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
  selectUncategorizedTransactionsNotNeedClarification,
  selectUserTransactionsByCategoryIdentifierForYear,
} from '../../../../Transactions/transactions.selectors'
import {
  invalidateFetch,
  selectErrorsForKeys,
} from '../../../../../reducers/fetch'
import { TransactionCategoryIdentifier } from '../../../../../reducers/admin/allTransactions.slice'
import { TAX_ENTITY_TYPES } from '../../../taxConstants'
import useProgressSteps, {
  StepUpdates,
} from '../Shared/ReviewStepsandProgresses/useProgressSteps'
import {
  SubStepIdentifiers,
  UPDATE_YOUR_BOOKS,
} from '../Shared/ReviewStepsandProgresses/stepProgress.helpers'
import { useAppDispatch } from '../../../../../utils/typeHelpers'

export enum UPDATE_YOUR_BOOKS_SCREENS {
  categorizeTransactions = 'categorizeTransactions',
  otherExpenses = 'otherExpenses',
  otherIncome = 'otherIncome',
  businessIncomeInPersonal = 'businessIncomeInPersonal',
  businessExpensesInPersonal = 'businessExpensesInPersonal',
  ownersInvestment = 'ownersInvestment',
  ownersDistribution = 'ownersDistribution',
}

export interface UpdateYourBooksStepProps {
  goForward: (_: StepUpdates) => Promise<boolean>
  goBack: () => void
}

const UpdateYourBooks = () => {
  const dispatch = useAppDispatch()
  const { formId } = useParams()
  const filingForm = useReselector(selectAnnualTaxFilingFormById, formId)
  const isCurrentForm1120s =
    filingForm?.formType?.name === TAX_ENTITY_TYPES.form_1120_s
  const [isLoading, setIsLoading] = useState(true)
  const relevantProgressSteps = useMemo(
    () =>
      //owners investment and owners distribution only apply to 1120-S forms
      UPDATE_YOUR_BOOKS.filter((step) =>
        !isCurrentForm1120s
          ? step !== SubStepIdentifiers.confirmOwnersInvestmentTransactions &&
            step !== SubStepIdentifiers.confirmOwnersDistributionTransactions
          : true
      ),
    [isCurrentForm1120s]
  )

  const {
    updateProgressData,
    fetchKeys: progressFetchKeys,
    userProgresses,
  } = useProgressSteps({
    steps: relevantProgressSteps,
  })

  const allFetchKeys = useMemo(
    () => [
      UPDATE_USER_TRANSACTION_KEY,
      DELETE_MANUAL_TRANSACTION_KEY,
      ...progressFetchKeys,
    ],
    [progressFetchKeys]
  )
  const errors = useReselector(selectErrorsForKeys, allFetchKeys)

  const { setScreen, currentScreen } = useSetScreen({
    backLink: `/taxes/annual/tax_checklist/${formId}`,
  })

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
    dispatch(fetchAnnualTaxFilingFormsIfNeeded())
  }, [dispatch])

  useEffect(() => {
    const fetch = async () => {
      if (!filingForm?.year) {
        return
      }
      await dispatch(
        fetchFilteredUserTransactions({
          startDate: moment().year(Number(filingForm.year)).startOf('year'),
          endDate: moment().year(Number(filingForm.year)).endOf('year'),
        })
      )
      // The loading structure of this flow is delicate.  The loading redux may complete before the transactions
      // selectors have changed which can cause incorrect flows or navigation.  Be careful when changing
      setIsLoading(false)
    }
    fetch()
  }, [dispatch, filingForm?.year])

  const uncatTransactions = useReselector(
    selectUncategorizedTransactionsNotNeedClarification,
    filingForm?.year
  )

  const needsNoteTransactions = useReselector(
    getUserNeedsClarificationTransactionsWithoutUserNoteForYear,
    filingForm?.year
  )

  const otherExpensesTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_expenses,
    filingForm?.year
  )

  const otherIncomeTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.other_income,
    filingForm?.year
  )

  const investmentTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_investments,
    filingForm?.year
  )

  const distributionTransactionsInState = useReselector(
    selectUserTransactionsByCategoryIdentifierForYear,
    TransactionCategoryIdentifier.owners_distribution,
    filingForm?.year
  )

  const [screenNamesToDisplay, setScreenNamesToDisplay] = useState<
    UPDATE_YOUR_BOOKS_SCREENS[]
  >([])

  useEffect(() => {
    if (isLoading || userProgresses.length === 0) {
      return
    }
    const newScreens: UPDATE_YOUR_BOOKS_SCREENS[] = []
    const completedSteps: SubStepIdentifiers[] = []
    const incompleteSteps: SubStepIdentifiers[] = []

    if (uncatTransactions.length || needsNoteTransactions.length) {
      newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.categorizeTransactions)
      incompleteSteps.push(SubStepIdentifiers.clarifyTransactions)
    } else {
      completedSteps.push(SubStepIdentifiers.clarifyTransactions)
    }

    if (otherExpensesTransactionsInState.length) {
      newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.otherExpenses)
      incompleteSteps.push(SubStepIdentifiers.categorizeOtherExpenses)
    } else {
      completedSteps.push(SubStepIdentifiers.categorizeOtherExpenses)
    }

    if (otherIncomeTransactionsInState.length) {
      newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.otherIncome)
      incompleteSteps.push(SubStepIdentifiers.categorizeOtherIncome)
    } else {
      completedSteps.push(SubStepIdentifiers.categorizeOtherIncome)
    }

    newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.businessIncomeInPersonal)
    newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.businessExpensesInPersonal)

    if (isCurrentForm1120s && investmentTransactionsInState.length) {
      newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.ownersInvestment)
      incompleteSteps.push(
        SubStepIdentifiers.confirmOwnersInvestmentTransactions
      )
    } else {
      completedSteps.push(
        SubStepIdentifiers.confirmOwnersInvestmentTransactions
      )
    }

    if (isCurrentForm1120s && distributionTransactionsInState.length) {
      newScreens.push(UPDATE_YOUR_BOOKS_SCREENS.ownersDistribution)
      incompleteSteps.push(
        SubStepIdentifiers.confirmOwnersDistributionTransactions
      )
    } else {
      completedSteps.push(
        SubStepIdentifiers.confirmOwnersDistributionTransactions
      )
    }

    // As the flow progresses required steps will be removed but we do NOT want these removed from the current flow,
    // just if it's opened another time.
    if (newScreens.length > screenNamesToDisplay.length) {
      setScreenNamesToDisplay(newScreens)

      // If the current screen isn't in the stack or is missing set it as the first screen
      if (!currentScreen || !newScreens.find((val) => val === currentScreen)) {
        setScreen(newScreens[0])
      }

      // Set the initial progress when loading the screens
      updateProgressData({
        incompleteSteps,
        completedSteps,
      })
    }
  }, [
    currentScreen,
    distributionTransactionsInState.length,
    investmentTransactionsInState.length,
    isLoading,
    isCurrentForm1120s,
    otherExpensesTransactionsInState.length,
    otherIncomeTransactionsInState.length,
    screenNamesToDisplay.length,
    setScreen,
    relevantProgressSteps,
    updateProgressData,
    userProgresses,
    uncatTransactions.length,
    needsNoteTransactions.length,
  ])

  const goForward = useCallback(
    async (stepUpdates: StepUpdates) => {
      allFetchKeys.forEach((key) => dispatch(invalidateFetch(key)))

      const progressUpdatesSuccessful = await updateProgressData(stepUpdates)
      if (!progressUpdatesSuccessful) {
        return false
      }

      const screenIndex = screenNamesToDisplay.findIndex(
        (screen) => screen === currentScreen
      )
      const isFinalScreen = screenIndex === screenNamesToDisplay.length - 1

      setScreen(
        screenIndex === -1 || isFinalScreen
          ? null
          : screenNamesToDisplay[screenIndex + 1]
      )
      return true
    },
    [
      allFetchKeys,
      updateProgressData,
      screenNamesToDisplay,
      setScreen,
      dispatch,
      currentScreen,
    ]
  )

  const goBack = useCallback(() => {
    const screenIndex = screenNamesToDisplay.findIndex(
      (screen) => screen === currentScreen
    )
    return setScreen(
      screenIndex === -1 || screenIndex === 0
        ? null
        : screenNamesToDisplay[screenIndex - 1]
    )
  }, [currentScreen, screenNamesToDisplay, setScreen])

  const screens = useMemo(() => {
    const props = { goForward, goBack }
    const newScreens = [
      {
        screenName: UPDATE_YOUR_BOOKS_SCREENS.categorizeTransactions,
        component: () => <CategorizeTransactionsStep {...props} />,
        step: screenNamesToDisplay.indexOf(
          UPDATE_YOUR_BOOKS_SCREENS.categorizeTransactions
        ),
      },
      {
        screenName: UPDATE_YOUR_BOOKS_SCREENS.businessIncomeInPersonal,
        component: () => <AddBusinessIncomeStep {...props} />,
        step: screenNamesToDisplay.indexOf(
          UPDATE_YOUR_BOOKS_SCREENS.businessIncomeInPersonal
        ),
      },
    ].filter((screen) => screenNamesToDisplay.includes(screen.screenName))

    let screenNum = 0
    newScreens.forEach((screen) => (screen.step = screenNum++))

    return newScreens
  }, [goBack, goForward, screenNamesToDisplay])

  const { progressBar, content } = useFormFlow({
    screens,
    noTitles: true,
    totalSteps: screens.length,
  })

  return (
    <>
      <PageHeader
        header={`${filingForm?.year} ${
          isCurrentForm1120s ? 'Business' : 'Personal'
        } Tax Questionnaire: Update Your Books`}
      />
      <Loader loading={isLoading} />
      <Grid>
        {!filingForm && !isLoading && (
          <GridRowColumn>
            <Alert type="error">Something went wrong</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{progressBar}</GridRowColumn>
        {errors.length > 0 && (
          <GridRowColumn>
            <Alert type="error">{errors[0].message}</Alert>
          </GridRowColumn>
        )}
        <GridRowColumn>{content}</GridRowColumn>
      </Grid>
    </>
  )
}

export default UpdateYourBooks
